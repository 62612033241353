<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template>
      <v-menu rounded offset-y :close-on-content-click="false" min-width="350">
        <template v-slot:activator="{ on }">
          <v-badge
            class="rounded-circle"
            color="red"
            :content="countNoRead"
            offset-x="20"
            offset-y="15"
            :value="countNoRead != 0"
            bordered
            top
          >
            <v-btn :disabled="data.length == 0" v-on="on" color="white" icon
              ><v-icon>mdi-reminder</v-icon></v-btn
            >
          </v-badge>
        </template>

        <v-sheet rounded class="d-flex flex-column pa-3">
          <div class="d-flex">
            <span class="font-weight-bold">Reminders</span>
            <v-spacer></v-spacer>
            <!-- <v-menu bottom close-on-click min-width="160">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon
                  ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                >
              </template>

              <v-list>
                <v-list-item @click="checkAll">
                  <v-list-item-title>{{
                    "Check all as read"
                  }}</v-list-item-title>
                </v-list-item>
                 <v-list-item @click="unreadAll">
                  <v-list-item-title>{{
                    "Check all as unread"
                  }}</v-list-item-title>
                </v-list-item> 
              </v-list>
            </v-menu>-->
          </div>
          <v-list>
            <v-list-item-group
              class="listnotify"
              v-model="selectedItem"
              color="primary"
            >
              <v-list-item v-for="item in data" :key="item.uuid">
                <v-list-item-content>
                  <v-list-item-title class="d-flex">
                    <div
                      :class="
                        item.status == 'PENDING'
                          ? 'font-weight-bold'
                          : 'text--secondary'
                      "
                    >
                      {{ item.content }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="error"
                          v-on="on"
                          x-small
                          icon
                          @click="toDelteReminder(item.uuid)"
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                      </template>
                      <span>Delete Reminder</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    :class="
                      item.status == 'PENDING'
                        ? 'text-right orange--text'
                        : 'text-right green--text'
                    "
                    >{{
                      item.status == "PENDING"
                        ? "Pending for: " + getDate(item.executeAt)
                        : "Executed on: " + getDate(item.executeAt)
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="text-right blue--text">{{
                    "Created at: " + getTime(item.createdAt)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
        <v-dialog
          v-model="deleteDialog"
          persistent
          :overlay="false"
          max-width="400px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title class="error white--text">
              Delete Reminder
            </v-card-title>
            <v-card-text class="mt-4 text-center">
              <h4>Are you sure you want delete this reminder?</h4>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn @click="cancelDelete" :disabled="loading" text
                >Cancel</v-btn
              >
              <v-btn
                color="error"
                :loading="loading"
                :disabled="loading"
                text
                @click="confirmDelte"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-menu>
    </template>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */

import { utilMixin } from "@/utils/mixins";
import longTime from "@/utils/longTime";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "./Notification";

export default {
  name: "notifications-lc",
  mixins: [utilMixin],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    from: {
      type: String,
      default: () => "Client",
    },
  },
  data() {
    return {
      loading: false,
      selectedItem: null,
      deleteDialog: false,
      uuidReminder: null,
    };
  },
  methods: {
    getTime(date) {
      return longTime(date);
    },

    readNotification(value) {
      getAPI
        .post("/notify/read/" + value)
        .then(() => {
          this.$emit("updateNotify");
        })
        .catch((err) => {
          let mess = err.response.data.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    readNotifications(value) {
      getAPI
        .post("/notify/read/" + value)
        .then(() => {
          //
        })
        .catch((err) => {
          let mess = err.response.data.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    /* unReadNotifications(value) {
      getAPI
        .post("/notify/update/" + value, { readed: false })
        .then(() => {
          //
        })
        .catch((err) => {
          let mess = err.response.data.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    }, */

    checkAll() {
      this.data.forEach(async (element) => {
        if (!element.readed) {
          await this.readNotifications(element.uuid);
        }
      });
      this.$emit("updateNotify");
    },
    /*  unreadAll() {
      this.notifications.forEach(async (element) => {
        if (element.readed) {
          await this.unReadNotifications(element.uuid);
        }
      });
      this.actGetNotifications();
    }, */

    toDelteReminder(value) {
      this.deleteDialog = true;
      this.uuidReminder = value;
    },
    cancelDelete() {
      this.deleteDialog = false;
      this.uuidReminder = null;
    },
    confirmDelte() {
      this.loading = true;

      getAPI
        .delete("/reminder/delete/" + this.uuidReminder)
        .then(() => {
          this.loading = false;
          this.cancelDelete();
          notifyInfo("Reminder has been delete");
          this.$emit("updateNotify");
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  watch: {
    selectedItem(val) {
      if (val != null) {
        const x = this.data[val];
        if (!x.readed) {
          //  this.readNotification(x.uuid);
        }
      }
    },
  },
  mounted() {
    //
  },
  computed: {
    countNoRead() {
      if (this.data.length == null || this.data == undefined) {
        return 0;
      }
      const x = this.data.filter((n) => n.status == "PENDING");
      return x.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.listnotify {
  max-height: 400px !important ;
  overflow-y: scroll;
  overflow-x: hidden;
}
.notifysheet {
  border-radius: 125px !important;
}
</style>
